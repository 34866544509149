<template>
    <base-material-card icon="mdi-human-male-board">
    <template v-slot:after-heading>
      <div class="font-weight-light card-title mt-2">
        {{ $t('conference.conferenciers') }}
      </div>
    </template>
    <v-text-field
      v-model="mots"
      append-icon="mdi-magnify"
      class="ml-auto"
      :label="$t('general.chercher')"
      hide-details
      single-line
      style="max-width: 250px;"
    />

    <v-divider class="mt-3" />

    <v-data-table
      :headers="headers"
      :items="affConferenciersConference"
      :options.sync="options"
      :server-items-length="count"
      :loading="loading"
      :search.sync="search"
      data-cy="dtConferenciers"
      :footer-props="{
                  itemsPerPageOptions: [10,50,100,500],
                  itemsPerPageText: $t('lignes_par_page'),
                  itemsPerPageAllText: $t('tout'),
                  pageText: `{0}-{1} ${$t('general.de')} {2}`
                }"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          class="px-2 ml-1 warning"
          min-width="0"
          small
          @click="onRetirerConférencier(item)"
        >
          <v-icon
            small
          >
            mdi-trash-can-outline
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <selection-usager
      :libelle="$t('conference.ajouter_un_conferencier')"
      :usagers-inscrits="tousLesConferenciers.map(i => i.usager.id)"
      @usager-selection="onUsagerIdentifié($event)"
    />
  </base-material-card>
</template>

<script>
  import restApiService from '@/services/restApiService.js'
  import SelectionUsager from './../../composantes/SelectionUsager'
  import i18n from '@/i18n.js'

  export default {
    components: {
      SelectionUsager,
    },
    props: {
      conference: Object,
    },
    data: () => ({
      loading: true,
      count: 0,
      search: undefined,
      mots: '',
      affConferenciersConference: [],
      tousLesConferenciers: [],
      options: {},
      headers: [
        { text: i18n.t('prenom'), value: 'usager.prenom' },
        { text: i18n.t('nom'), value: 'usager.nom' },
        { text: i18n.t('actions'), value: 'actions' },
      ],
    }
    ),
    watch: {
      options: {
        handler () {
          this.getData()
        },
        deep: true,
      },
      conference: {
        handler (newValue) {
          if (newValue !== undefined) {
            this.getData()
          }
        },
      },
      mots: {
        handler () {
          this.getData()
        },
        deep: true,
      },

    },
    methods: {
      getData () {
        if (!this.conference.id) {
          return
        }
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        const s = sortDesc[0] ? ',desc' : ''
        const arg = `size=${itemsPerPage}&page=${page - 1}&sort=${sortBy + s}`
        restApiService
          .get(`/api/conferences/${this.conference.id}/aff-conferencier-conferences?${arg}&mots=${this.mots}`)
          .then((result) => {
            this.affConferenciersConference = result.data
            this.count = parseInt(result.headers['x-total-count'])
            this.loading = false
          })
          .catch((erreur) => {
            alert(erreur)
            this.loading = false
          })
        this.getTousLesConferenciers()
      },
      getTousLesConferenciers() {
        const args = 'size=2147483647&page=0'
        restApiService
          .get(`/api/conferences/${this.conference.id}/aff-conferencier-conferences?${args}`)
          .then((result) => {
            console.log(result.data, 'toutes les participations')
            this.tousLesConferenciers = result.data
          })
      },
      onRetirerConférencier (affConferencierConference) {
        restApiService
          .delete('/api/aff-conferencier-conferences/' + affConferencierConference.id)
          .then((result) => {
            this.getData()
          })
          .catch((erreur) => {
            alert(erreur)
          })
      },
      onUsagerIdentifié (u) {
        const info = {
          usager: {
            id: u.id,
          },
          creePar: this.$store.state.userInfo.prenom + ' ' + this.$store.state.userInfo.nom,
          conference: {
            id: this.conference.id,
          },
        }
        restApiService
          .post('/api/aff-conferencier-conferences', info)
          .then((result) => {
            this.getData()
          })
          .catch((erreur) => {
            alert(erreur)
          })
      },
    },
  }
</script>

<style>

</style>
