<template>
  <v-container
    fluid
    tag="section"
    data-cy="pageEditionConference"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="8"
      >
        <base-material-card icon="mdi-account-outline">
          <template v-slot:after-heading>
            <div class="font-weight-light card-title mt-2">
              {{ conference.nom }}
              <span class="body-1">— {{ $t('edition.atelier') }}</span>
            </div>
          </template>
          <v-form>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="conference.nom"
                  :label="$t('general.nom')"
                  data-cy="tfNomConference"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="conference.identifiant"
                  :label="$t('general.identifiant')"
                  data-cy="tfIdentifiantConference"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <cnx-date-time
                  :label="$t('conference.ouverture_des_portes')"
                  :value.sync="conference.dateOuverture"
                  data-cy="dtOuverturePorte"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <cnx-date-time
                  :value.sync="conference.dateFermeture"
                  :label="$t('conference.fermeture_des_portes')"
                  data-cy="dtFermeturePorte"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <cnx-date-time
                  :value.sync="conference.dateDebut"
                  :label="$t('conference.debut_de_la_conference')"
                  data-cy="dtDateDebut"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <cnx-date-time
                  :label="$t('conference.fin_de_la_conference')"
                  :value.sync="conference.dateFin"
                  data-cy="dtDateFin"
                />
              </v-col>
            </v-row>
            <v-text-field
              v-model="conference.urlPresentation"
              :label="$t('conference.url_de_presentation')"
              data-cy="tfUrlPresentationConference"
            />

            <label>{{ $t('general.description') }}</label>
            <cnx-editeur
              data-cy="ckeDescription"
              :texte.sync="conference.description"
            />
            <label>Information</label>
            <cnx-editeur
              data-cy="ckeInformation"
              :texte.sync="conference.information"
            />
          </v-form>
          <v-btn
            color="primary"
            data-cy="btnSauvegarderConference"
            class="mr-0 mt-2"
            @click="onSauvegarder()"
          >
            {{ $t('general.sauvegarder') }}
          </v-btn>
        </base-material-card>
      </v-col>

      <v-col
        cols="12"
        md="4"
      >
        <base-material-card icon="mdi-cog-outline">
          <template v-slot:after-heading>
            <div class="font-weight-light card-title mt-2">
              {{ $t('conference.parametres_de_conference') }}
            </div>
          </template>
          <v-checkbox
            v-model="conference.ouvertAtous"
            :label="$t('conference.ouvert_a_tous')"
            data-cy="cbOuvertATous"
          />
          <v-checkbox
            v-model="conference.masquerFilDiscussion"
            :label="$t('conference.masquer_le_fil_de_discussion')"
          />
          <v-checkbox
            v-model="conference.priseParole"
            :label="$t('prise_parole.edition.titre_bool')"
          />
          <v-text-field
            v-if="conference.priseParole"
            v-model="conference.urlPriseParole"
            :label="$t('prise_parole.edition.titre_url')"
          />
        </base-material-card>
        <v-card>
          <v-card-text class="text-center">
            <h6 class="display-1 mb-1 grey--text">
              {{ $t('general.image') }}
            </h6>
            <boite-media
              :media.sync="mediaImage"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <conferenciers-conference
      data-cy="affConferenciersConference"
      :conference="conference"
    />
    <participations-conference
      data-cy="participationConferenceCard"
      :conference="conference"
    />
    <v-row>
      <liste-ateliers :conference="conference" />
    </v-row>
  </v-container>
</template>

<script>

  import restApiService from '@/services/restApiService.js'
  import BoiteMedia from './../../composantes/BoiteMedia'
  import ParticipationsConference from './ParticipationsConference'
  import ListeAteliers from './ListeAteliers'
  import CnxEditeur from '../../composantes/CnxEditeur.vue'
  import i18n from '@/i18n.js'
  import CnxDateTime from '../../composantes/CnxDateTime.vue'
  import ConferenciersConference from './ConferenciersConference'

  export default {
    components: {
      BoiteMedia,
      ParticipationsConference,
      ListeAteliers,
      CnxEditeur,
      CnxDateTime,
      ConferenciersConference,
    },
    data: () => ({
      conference: {},
      options: {},
      mediaImage: {},
    }),
    mounted () {
      this.chargerConference()
    },
    methods: {
      chargerConference () {
        restApiService
          .get(`/api/conferences/${this.$route.params.idConference}`)
          .then((result) => {
            this.conference = result.data
            if (!this.conference.description) { this.conference.description = '' }
            if (this.conference.mediaImage) {
              restApiService.getMedia(this.conference.mediaImage).then((m) => {
                this.mediaImage = m.data
              })
            }
          })
          .catch((erreur) => alert(erreur))
      },
      onSauvegarder () {
        restApiService
          .put('/api/conferences', this.conference)
          .then(() => {
            this.$dialog.notify.success(i18n.t('general.sauvegarde'))
          })
          .catch((erreur) => alert(erreur))
        if (Object.keys(this.mediaImage).length) {
          restApiService
            .put(`/api/conferences/${this.conference.id}/media`, this.mediaImage)
            .catch((erreur) => alert(erreur))
        }
      },
      majImage () {
        const reader = new FileReader()
        reader.onloadend = (e) => {
          const data = window.btoa(reader.result)
          this.conference.image = data
          this.conference.imageContentType = this.fichiers.type
        }
        reader.readAsBinaryString(this.fichiers)
      },
    },
    //
  }
</script>
